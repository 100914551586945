import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Context } from "../components/context"
import Img from "gatsby-image"
import Layout from "../components/layout"
import { Container, SectionPage, SectionPageTitle } from "../components/Section"
import { StaticImage } from "gatsby-plugin-image"
import { Nodata, NodataDesc, NodataMedia } from "../components/Nodata"
import PrimaryButton from "../components/Button/PrimaryButton"
import CloseIcon from "../components/Icons/CloseIcon"
import SecondaryButton from "../components/Button/SecondaryButton"
import QuotePopup from "../components/QuotePopup"
import { Link } from "gatsby"
import { getProductUrl } from "../utils/product"

const TableCompare = styled.div`
  margin: 0;
`
const TableResponsive = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  tr {
    border: 0;
    border-bottom: 1px solid #f7f7f7;
  }
  tr:first-child {
    border-top: 1px solid #f7f7f7;
  }
  td.pro-heading {
    text-align: right;
    font-weight: 500;
    background: #ff7245;
    color: #fff;
    border-right: 2px solid #f7f7f7;
    width: 100px;
    @media (min-width: 768px) {
      width: 150px;
    }
  }
  td.pro-title {
    padding: 8px 30px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    background-color: #ebf6ff;
    text-align: left;
    color: #062c44;
  }
  td {
    padding: 8px 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: left;
    background: #deedfd;
    font-size: 14px;
    line-height: 22px;
    color: #062c44;
  }
  td.pro-desc {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td.product-thumb {
    text-align: center;
    .gatsby-image-wrapper,
    img {
      width: 200px;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  }
  td:nth-child(2n + 3) {
    background: #cae2fc;
  }
  td.action {
    min-width: 250px;
    text-align: center;
    .btn {
      font-size: 14px;
      line-height: 20px;
      padding: 3px 9px;
      font-weight: 400;
    }
  }
`

export const ProductTitle = styled.div`
  position: relative;
  padding-right: 30px;
  display:flex;
  flex-wrap:wrap;
`
export const ProductTitleText = styled.div`
    font-size: 14px;
    line-height: 22px;
`

export const CloseWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const CloseBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 12px;
  border: 1px solid #062C44;
  svg {
    fill: #062C44;
  }
  &:hover{
    border-color:#ff3e00;
    svg {
      fill: #ff3e00;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: center;
`

const displayFields = [
  "Product",
  "Image",
  "Price",
  "Discount",
  "SKU",
  "Gauge",
  "Roof Style",
  "Building Dimensions (WxL)",
  "Square Footage (Sq. Ft.)",
  "Height",
  "RTO & Financing",
  "Description",
  "Actions",
]

const ProductCompare = ({ location }) => {
  const { comparisonData, removeProduct } = useContext(Context)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const HandleModalOpen = () => {
    setIsModalVisible(true)
  }
  const HandleModalClose = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    if (isModalVisible) {
      document.querySelector("body").classList.add("modal-open")
    } else {
      document.querySelector("body").classList.remove("modal-open")
    }
  }, [isModalVisible, setIsModalVisible])

  const getServies = product => {
    const services = []
    if (product.productServices) {
      product.productServices.forEach(function(item) {
        if (item.service === "Financing Available") {
          services.push("Financing Available")
        }

        if (item.service === "Rent To Own Available") {
          services.push("Rent To Own Available")
        }
      })
    }
    return services.join(", ")
  }

  const renderCell = (field, product, no) => {
    switch (field) {
      case "Product":
        return (
          <td key={no}>
            <ProductTitle>
              <ProductTitleText>{product.title}</ProductTitleText>
              <CloseWrap><CloseBtn onClick={() => removeProduct(product.id)}><CloseIcon /></CloseBtn></CloseWrap>
            </ProductTitle>
          </td>
        )
      case "Image":
        return (
          <td className="product-thumb" key={no}>
            {product.image ? (
              <Img fluid={product.image.fluid} alt={product.title} />
            ) : (
              <img src={product.imageSrc} alt={product.title} />
            )}
          </td>
        )
      case "Price":
        return (
          <td key={no}>
            <strong>${product.price.toLocaleString("en-US")}</strong>
          </td>
        )
      case "Discount":
        let finalPrice = product.price
        if (product.price > 20000) {
          finalPrice = finalPrice * 0.85
        } else if (product.price > 10000) {
          finalPrice = finalPrice * 0.9
        } else if (product.price > 5000) {
          finalPrice = finalPrice * 0.95
        }
        return <td key={no}>${finalPrice}</td>
      case "SKU":
        return <td key={no}>PB#{product.skuNumber}</td>

      case "Gauge":
        return <td key={no}>{product.gauge}</td>
      case "Roof Style":
        return <td key={no}>{product.productRoofType?.name}</td>
      case "Building Dimensions (WxL)":
        return <td key={no}>{`${product.width}'x${product.length}'`}</td>
      case "Square Footage (Sq. Ft.)":
        return <td key={no}>{`${product.width * product.length}sqft`}</td>
      case "Height":
        return <td key={no}>{product.height}</td>
      case "RTO & Financing":
        return <td key={no}>{getServies(product)}</td>
      case "Description":
        return (
          <td key={no} className="pro-desc">
            <p>{product.description.description}</p>
          </td>
        )
      case "Actions":
        const productUrl = getProductUrl(product)
        return (
          <td className="action" key={no}>
            <Actions>
              <button
                type="button"
                aria-label="get a quote"
                onClick={HandleModalOpen}
              >
                <SecondaryButton text="Get a Quote"/>
              </button>

              <Link to={productUrl}>
                <PrimaryButton text="View Details" />
              </Link>
            </Actions>
          </td>
        )
      default:
        return null
    }
  }

  if (!comparisonData || comparisonData.length === 0) {
    return (
      <>
        <SectionPageTitle>Product Comparison</SectionPageTitle>
        <Nodata>
          <NodataMedia>
            <StaticImage src="../images/no-result.png" alt="no-result" />
          </NodataMedia>
          <NodataDesc mb="30px">
            <p>You have not chosen any products to compare.</p>
          </NodataDesc>
        </Nodata>
      </>
    )
  }

  return (
    <>
      <SectionPageTitle>Product Comparison</SectionPageTitle>
      <TableCompare>
        <TableResponsive className="table-responsive">
          <Table class="table">
            <tbody>
              <tr>
                <td colSpan={comparisonData.length + 1} className="pro-title">
                  Overview
                </td>
              </tr>
              {displayFields.map((field, j) => (
                <tr key={j}>
                  <td className="pro-heading">{field}</td>
                  {comparisonData.map((p, i) => renderCell(field, p, i))}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableResponsive>
      </TableCompare>
      <QuotePopup
        isVisible={isModalVisible}
        onClose={HandleModalClose}
        location={location}
        formName="HomePage Quote Form"
      />
    </>
  )
}

const Page = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Compare Metal Buildings, Specs & Features | Probuilt Steel Buildings" description="Discover and compare metal buildings to make an informed choice. Compare the specifications, prices, and features of different types of metal buildings to find the perfect one that suits your needs." />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <ProductCompare location={location} />
        </Container>
      </SectionPage>
    </Layout>
  )
}

export default Page
